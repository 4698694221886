/* Mobile */

.diploma-viewer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
}

.diploma{
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    display: block;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .diploma{
        width: 800px;
    }

}

/* Large Screens */
 @media screen and (min-width: 1536px){

     .diploma{
        width: 1000px;
     }

 }