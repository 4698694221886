/* Mobile */

.collaboration-badge{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    margin: -10px 0 5px;
    width: 100%;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
/*@media screen and (min-width: 1280px){}*/

/* Large Screens */
/* @media screen and (min-width: 1536px){} */