/* Mobile */

.profile-picture-container {
    height: 200px;
    width: 200px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-picture {
    height: auto;
    width: 100%;
    transform: translate(-10px, 50px);
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .profile-picture-container {
        height: 250px;
        width: 250px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .profile-picture-container {
        height: 300px;
        width: 300px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .profile-picture-container {
        height: 350px;
        width: 350px;
    }

    .profile-picture {
        transform: translate(-10px, 70px);
    }


}

/* Laptops */
@media screen and (min-width: 1280px){

    .profile-picture-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        border-radius: 0;
    }

    .profile-picture {
        height: 85vh;
        width: auto;
        transform: translate(0, 0);
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .profile-picture {
        height: 85vh;
        width: auto;
        transform: translate(0, 0);
    }

}