/* Mobile */

.menu{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 25px;
    padding: 0 40px;
    background-color: var(--primary-color);
}

.menu-link{
    font-size: 28px;
    transform: translateX(-300px);
    animation: slideIn 1s ease forwards;
    margin: 0;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .menu{
        row-gap: 45px;
        padding: 0 60px;
    }

    .menu-link{
        font-size: 32px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .menu{
        row-gap: 50px;
        padding: 0 60px;
    }

    .menu-link{
        font-size: 36px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .menu{
        row-gap: 60px;
        padding: 0 60px;
    }

    .menu-link{
        font-size: 40px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .menu{
        row-gap: 40px;
        padding: 0 50px;
    }

    .menu-link{
        font-size: 28px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .menu{
        row-gap: 50px;
        padding: 0 80px;
    }

    .menu-link{
        font-size: 32px;
    }

}

@keyframes slideIn {

    0%{
        transform: translateX(-300px);
    }

    50%{
        transform: translateX(20px);
    }

    100%{
        transform: translateX(0px);
    }

}