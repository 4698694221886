/* Mobile */

.credential{
    width: 100%;
}

.credential-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
}

.credential-title{
    line-height: 25px;
}

.credential-logo{
    font-size: 25px;
}

.credential-verification{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.credential-verification button{
    width: 100%;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .credential-title{
        line-height: 0;
    }

}

/* Medium Tablets */
 @media screen and (min-width: 768px){

    .credential-verification button{
        width: 250px;
    }

 }

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .credential-verification button{
        width: 150px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .credential-verification button{
        width: 200px;
    }

}

@keyframes fillIn {
    0%{
        width: 0;
    }

    100%{
        width: 100%;
    }
}