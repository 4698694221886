/* Mobile */

.skill-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 20px;
    width: 100%;
    padding: 20px;
    border: var(--button-border-color);
    border-radius: var(--button-border-radius);
    cursor: pointer;
}

.card-expanded{
    display: block;
}

.card-hidden{
    display: none;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .skill-card{
        max-height: 75vh;
        overflow-y: auto;
    }

}

/* Large Screens */
/* @media screen and (min-width: 1536px){} */