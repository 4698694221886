/* Mobile */

.credentials{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    padding: var(--content-padding);
}

.credentials-showcase{
    display: none;
}

.credentials-showcase-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    height: 100%;
    width: 100%;
}


/* Mobile */

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
/*@media screen and (min-width: 1280px){}*/

/* Large Screens */
/* @media screen and (min-width: 1536px){} */

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .credentials{
        position: absolute;
        height: 100%;
        width: 100%;
        display: grid;
        column-gap: 50px;
        grid-template-columns: 50% 50%;
    }

    .credentials-showcase{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
        height: 100%;
        width: 100%;
    }

    .credentials-showcase-mobile{
        display: none;
    }

    .credentials-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}

/* Large Screens */
/* @media screen and (min-width: 1536px){} */