/* Mobile */

.skill{
    width: 100%;
}

.skill-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expertise-bar-container{
    height: 10px;
    border-radius: var(--input-border-radius);
    background-color: rgba(70, 111, 173, 0.10);
}

.expertise-level-bar{
    height: 100%;
    border-radius: var(--input-border-radius);
    background-color: rgba(70, 111, 173, 0.5);
    animation: fillIn 2s ease-in-out forwards;
}

.expertise-level{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.level-label{
    color: var(--content-text-color);
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
/*@media screen and (min-width: 1280px){}*/

/* Large Screens */
/* @media screen and (min-width: 1536px){} */

@keyframes fillIn {
    0%{
        width: 0;
    }

    100%{
        width: 100%;
    }
}