/* Mobile */

.navbar{
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    padding: var(--global-padding);
    border-bottom: var(--button-border-color);
    background-color: var(--primary-color);
    z-index: 1;
}

.navbar-icon{
    color: var(--content-text-color);
    font-size: 28px;
    cursor: pointer;
}

.lang-toggler{
    cursor: pointer;
    color: rgba(70, 111, 173, 0.75);
}

.lang-toggle-active{
    color: var(--content-text-color);
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .navbar{
        height: 70px;
    }

    .navbar-icon{
        font-size: 32px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .navbar{
        height: 75px;
    }

    .navbar-icon{
        font-size: 36px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .navbar{
        height: 85px;
    }

    .navbar-icon{
        font-size: 40px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .navbar{
        height: 75px;
    }

    .navbar-icon{
        font-size: 32px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .navbar{
        height: 85px;
    }

    .navbar-icon{
        font-size: 36px;
    }

}