/* Mobile */
.viewer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.project-content{
    overflow-y: auto;
}

.banner {
    height: 235px;
    width: 100%;
    overflow: hidden;
}

.project-banner {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.description {
    padding: var(--global-padding);
    flex-grow: 1;
}

.project-skills {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    border-top: var(--button-border-color);
    padding-top: 20px;
}

.viewer-controller {
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    padding: var(--global-padding);
    background-color: var(--primary-color);
    border-radius: 0 0 10px 10px;
    z-index: 1;
}

/* Small Tablets */
@media screen and (min-width: 640px) {
    .viewer {
        width: 500px;
        height: 800px;
        border-radius: var(--button-border-radius);
    }

    .banner {
        height: 300px;
        border-radius: 10px 10px 0 0;
    }
}

/* Medium Tablets */
/*@media screen and (min-width: 768px){}*/

/* Large Tablets */
@media screen and (min-width: 1024px) {

    .viewer {
        width: 650px;
        height: 1000px;
    }

    .banner {
        height: 350px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px) {

    .viewer {
        width: 500px;
        height: 650px;
    }

    .banner {
        height: 275px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px) {

    .viewer {
        width: 550px;
        height: 850px;
    }

    .banner {
        height: 300px;
    }

}
