/* Mobile */

.contact-me-submit{
    margin-top: 0;
}

.invalid-input{
    border: 1px solid #ad4646;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .contact-me-form{
        margin-top: 50px;
    }

}

/* Large Screens */
/* @media screen and (min-width: 1536px){} */