/* Mobile */

.project-card{
    min-width: 100%;
    border-radius: var(--button-border-radius);
    border: var(--button-border-color);
}

.project-banner{
    height: 200px;
    width: 100%;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
}

.project-banner-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.project-info{
    padding: var(--global-padding);
}

.project-description{
     display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .project-card{
        min-width: calc(100% / 2 - 5px);
        max-width: calc(100% / 2 - 5px);
    }

    .project-banner{
        height: 200px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .project-card{
        min-width: calc(100% / 2 - 5px);
        max-width: calc(100% / 2 - 5px);
    }

    .project-banner{
        height: 250px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .project-card{
        min-width: calc(100% / 2 - 5px);
        max-width: calc(100% / 2 - 5px);
    }

    .project-banner{
        height: 250px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .project-card{
        min-width: calc(100% / 3 - 7px);
        max-width: calc(100% / 3 - 7px);
    }

    .project-banner{
        height: 200px;
    }

}

/* Large Screens */
 @media screen and (min-width: 1536px){

     .project-card{
        min-width: calc(100% / 3 - 7px);
        max-width: calc(100% / 3 - 7px);
    }

     .project-banner{
        height: 250px;
    }


 }