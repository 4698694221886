/* Mobile */

.contact-links{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.contact-icon{
    color: var(--content-text-color);
    height: var(--button-height);
    width: var(--button-height);
    cursor: pointer;
}

.cv{
    width: 100%;
}

.cv-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cv-button svg{
    font-size: 18px;
    color: var(--content-text-color);
}


/* Small Tablets */
@media screen and (min-width: 640px){

    .cv{
        width: 300px;
    }

     .cv-button svg{
        font-size: 20px;
     }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .cv{
        width: 300px;
    }

    .cv-button svg{
        font-size: 24px;
     }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .cv{
        width: 300px;
    }

    .cv-button svg{
        font-size: 24px;
     }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .cv{
        width: 300px;
    }

    .cv-button svg{
        font-size: 24px;
     }

}

/* Large Screens */
 @media screen and (min-width: 1536px){

     .cv{
        width: 350px;
    }

     .cv-button svg{
        font-size: 28px;
     }

 }