/* Mobile */

.projects{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: var(--content-padding);
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .projects{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 100px 250px;
    }

}

/* Large Screens */
 @media screen and (min-width: 1536px){

     .projects{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 100px 350px;
    }

 }