/* Mobile */

.skill-tile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: calc(100% / 2 - 5px);
    border: 1px solid red;
    padding: var(--global-padding);
    border: var(--button-border-color);
    border-radius: var(--button-border-radius);
}


/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
/*@media screen and (min-width: 1280px){}*/

/* Large Screens */
/* @media screen and (min-width: 1536px){} */