/* Mobile */

.about-me{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--content-padding);
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .about-me{
        position: absolute;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .about-me-picture{
        height: 100%;
        width: 100%;
    }

    .about-me-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

}

/* Large Screens */
/* @media screen and (min-width: 1536px){} */