/* Mobile */

.project-filter{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 0 10px;
    width: 100%;
}

.filter-button-active{
    background-color: #466FAD;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

@media screen and (max-width: 1023px){

    .filter-button{
        width: calc(100% / 2 - 5px);
        margin-bottom: 0;
    }

}

/* Large Tablets */
 @media screen and (min-width: 1024px){

     .project-filter{
         flex-wrap: nowrap;
     }

     .filter-button{
        width: 100%;
    }

 }

/* Laptops */
/*@media screen and (min-width: 1280px){}*/

/* Large Screens */
/* @media screen and (min-width: 1536px){} */