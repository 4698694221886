/* Mobile */

.loader{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader-spinner{
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 2px solid transparent;
    border-top: 2px solid #FFFFFF;
    animation: 1s spin linear infinite;

}

.loader-text{
    color: var(--content-text-color);
    animation: 1s fadeInOut linear infinite alternate;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
/*@media screen and (min-width: 1280px){}*/

/* Large Screens */
/* @media screen and (min-width: 1536px){} */

@keyframes spin {

    0%{
        transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }

}

@keyframes fadeInOut {

    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }

}